import React from 'react'
import { Link } from 'react-router-dom'

function HeroArea() {
    const scrollTop =()=>window.scrollTo({top:0,behavior:"smooth"})
  return (
    <>
     <section className="hero-area">
        <div className="ken-burns-slideshow">
          <img src={process.env.PUBLIC_URL + "/img/hero-banner.jpg"} alt="HeroBanner" />
        </div>
        <div className="verticale-social">
          <ul className="vertical-media">
            <li><a href="https://www.facebook.com/">Facebook</a></li>
            <li><a href="https://www.instagram.com/">Instagram</a></li>
            <li><a href="https://www.linkedin.com/">Linkedin</a></li>
          </ul>
        </div>
        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero-content">
                  {/* <h1>Creative &amp; Minimal<span>It Agency.</span></h1> */}
                  {/* <h1>Creando soluciones, Cumpliendo<span> sueños.</span></h1> */}
                  {/* <h1>Innovación Tecnológica &amp; <span>soluciones <br />digitales</span></h1> */}
                  <h1>Transformamos tu futuro <span>digital</span></h1>
                  {/* <p>Curabitur sed facilisis erat. Vestibulum pharetra eros eget fringilla porttitor. ol Duis a orci nunc. Suspendisse ac convallis sapien, quis commodo libero. Donec nec dui luctus, pellentesque lacus sed, mollis leo.</p> */}
                  <p>
                    Fusionamos creatividad y tecnología para ofrecer soluciones de software a medida que 
                    destacan por su simplicidad, eficacia y alto rendimiento. Somos expertos en transformar 
                    ideas complejas en experiencias digitales intuitivas, diseñadas para superar expectativas y
                    optimizar resultados. Con un enfoque minimalista y atención obsesiva al detalle, 
                    entregamos proyectos que no solo funcionan, sino que también inspiran. 
                    Desde aplicaciones web hasta infraestructura en la nube, nuestra meta es potenciar tu 
                    crecimiento tecnológico. Confía en nuestro equipo comprometido con la calidad, 
                    profesionalismo y satisfacción total, llevando tu visión al siguiente nivel.
                  </p>
                  <div className="buttons">
                    <div className="cmn-btn">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link to={`${process.env.PUBLIC_URL}/about`} onClick={scrollTop}>Nosotros</Link>
                    </div>
                    <div className="cmn-btn layout-two">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link to={`${process.env.PUBLIC_URL}/service`} onClick={scrollTop}>Servicios</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default HeroArea