import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  Navigation,
} from "swiper";
SwiperCore.use([Navigation, Autoplay]);

function ServiceArea(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const serviceSlider= {
    loop: true,
    speed: 1000,
    autoplay:{
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 30,

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        576: {
            slidesPerView: 2,
        },
        992: {
            slidesPerView: 3,
        }
    }
  }
  return (
    <>
      <section className={`${"services-area sec-mar"} ${props.layoutClass}`}>
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Nuestras Soluciones</span>
              <h2>Servicios</h2>
              <p>
                En Gekkonect transformamos tus ideas en soluciones tecnológicas personalizadas, diseñadas para 
                impulsar tu negocio. Nuestro equipo de expertos combina innovación, calidad y eficiencia para 
                desarrollar software a medida, que se adapta perfectamente a tus necesidades. Ofrecemos desde 
                aplicaciones móviles innovadoras hasta integraciones tecnológicas y infraestructura en la nube, 
                asegurando que cada proyecto maximice su rendimiento y escalabilidad. Explora nuestros servicios y 
                descubre cómo podemos hacer realidad tus proyectos con precisión, optimizando tus sistemas y garantizando
                resultados excepcionales.
              </p>
            </div>
          </div>
          <Swiper {...serviceSlider} className="swiper services-slider">
            <div className="swiper-wrapper">
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>01</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-1.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Diseño Web Impactante</h4>
                  <p>
                    Creamos sitios web atractivos y funcionales que capturan la esencia de tu marca. Combinamos creatividad y usabilidad para ofrecer experiencias únicas que cautivan a tus clientes desde el primer clic.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Más información
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>02</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-2.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>UI/UX que Enamora</h4>
                  <p>
                    Diseñamos interfaces intuitivas y experiencias de usuario memorables, 
                    centradas en facilitar cada interacción. Tu producto no solo se verá increíble, 
                    sino que funcionará a la perfección, cautivando a tus usuarios en cada paso.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Más información
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>03</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Software Personalizado</h4>
                  <p>
                    Transformamos tus ideas en soluciones únicas y escalables, ajustadas a la perfección para satisfacer las demandas específicas de tu negocio. Tu visión, nuestro código.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Más información
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>04</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Aplicaciones Web de Alto Rendimiento</h4>
                  <p>
                    Creamos aplicaciones web rápidas, seguras y optimizadas que garantizan una experiencia de usuario fluida en cualquier dispositivo, maximizando tu presencia digital.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Más información
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>05</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Integraciones de Sistemas Empresariales</h4>
                  <p>
                    Conectamos tus sistemas para que trabajen en perfecta sincronía, optimizando procesos y mejorando la eficiencia operativa de tu empresa a través de integraciones robustas.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Más información
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>06</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Soluciones Móviles Innovadoras</h4>
                  <p>
                    Desarrollamos apps móviles intuitivas y potentes que mantienen a tus clientes conectados con tu marca, desde cualquier lugar y en cualquier momento.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Más información
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>07</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Inteligencia Artificial y Machine Learning</h4>
                  <p>
                    Potenciamos tu negocio con soluciones avanzadas de IA que transforman datos en decisiones estratégicas, brindando automatización y predicciones precisas.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Más información
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>08</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Consultoría Tecnológica Estratégica</h4>
                  <p>
                    Te guiamos en la adopción de tecnologías de vanguardia, optimizando tus recursos y asegurando un crecimiento sostenible y competitivo en tu sector.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Más información
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>9</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Desarrollo de APIs y Microservicios</h4>
                  <p>
                    Creamos APIs flexibles y microservicios eficientes que facilitan la conectividad y la escalabilidad, impulsando el crecimiento ágil de tu infraestructura tecnológica.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Más información
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>10</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Optimización y Modernización de Software</h4>
                  <p>
                    Renovamos tu software heredado para mejorar su rendimiento, seguridad y funcionalidad, adaptándolo a las exigencias tecnológicas actuales sin interrumpir tu operación.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Más información
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>11</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Plataformas SaaS a Medida</h4>
                  <p>
                    Diseñamos y desarrollamos plataformas SaaS innovadoras que proporcionan soluciones de software escalables y fáciles de usar, listas para conquistar el mercado.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Más información
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>11</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Arquitectura AWS</h4>
                  <p>
                    Ofrecemos diseño y desarrollo de arquitecturas robustas en AWS, adaptadas a tus necesidades. 
                    Desde la creación de estructuras a medida hasta la implementación de soluciones con productos AWS, 
                    optimizamos tu infraestructura en la nube para lograr eficiencia, escalabilidad y seguridad.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Más información
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}

export default ServiceArea;
