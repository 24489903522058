import React, { useState } from 'react'
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutArea(props) {
  const [isOpen, setOpen]=useState(false)
  return (
    <>
      <section className="about-area sec-mar-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 or-2 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className={`${"sec-title layout2"} ${props.white}`}>
                <span>Conózcanos un poco</span>
                <h2>Nosotros</h2>
              </div>
              <div className={`${"about-left"} ${props.layoutClass}`}>
                <h3>Nos encargamos del diseño, codificación &amp; y desarrollo del software para su lanzamiento final.</h3>
                <p>
                  Somos un equipo apasionado por transformar desafíos en soluciones tecnológicas innovadoras, 
                  desde software a medida hasta integraciones en la nube y APIs escalables. Con un enfoque en la 
                  excelencia y el detalle, nos dedicamos a desarrollar software personalizado que no solo cumple, 
                  sino que supera las expectativas de rendimiento y calidad. Cada proyecto es una oportunidad para 
                  crear algo único, optimizando sistemas y garantizando la satisfacción total del cliente, siempre 
                  con el compromiso de impulsar el éxito de nuestros clientes.
                </p>
                <div className={`${"company-since"} ${props.layoutClass}`}>
                  {/* <div className="company-logo">
                    <img src={`${process.env.PUBLIC_URL} ${props.image}`} alt="about-images" />
                    <img src={process.env.PUBLIC_URL + "/img/logo-gekkonect-last-round-light.png"} alt="about-images" />
                  </div> */}
                  <strong>#1</strong>
                  <h4>Esforzándonos al máximo <span>desde 2011.</span></h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 or-1 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="about-right">
                <div className="banner-1">
                  <img src={process.env.PUBLIC_URL + '/img/about-baner-1.jpg'} alt="about-images" />
                </div>
                <div className="banner-2">
                  <img src={process.env.PUBLIC_URL + '/img/about-baner-2.jpg'}  alt="about-images" />
                  <div className="banner2-inner">
                    <div className="play">
                      <div className="video-popup" onClick={()=>setOpen(true)} ><i className="fas fa-play" /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>  
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="IUN664s7N-c"
          autoplay = {1}
          onClose={() => setOpen(false)}
        />
      </React.Fragment> 
    </>
  )
}

export default AboutArea