import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  
  Autoplay,
  Navigation,
  EffectFade,
  Pagination
} from "swiper";
SwiperCore.use([Navigation, Autoplay, EffectFade,Pagination,]);
function Testimonial() {
    const testimonialSlider = {
        loop: true,
        slidesPerView: 2,
        centeredSlides: true,
        duration: 5000,
        speed: 1000,
        autoplay:{
          delay: 3500,
          isableOnInteraction: true,
        },
        spaceBetween: 30,
        slideToClickedSlide: true,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 2,
            },
            1400: {
                slidesPerView: 2,
            },
            1800: {
                slidesPerView: 2,
            }
        }
    }
  return (
    <>
      <section className="testimonial-area">
        <div className="container-fluid p-0">
          <div className="title-wrap">
            <div className="sec-title white">
            <span>Testimonios</span>
              <h2>Qué opinan nuestros clientes</h2>
              <p>
                Nuestros clientes comparten sus experiencias, 
                destacando no solo los resultados alcanzados, sino también el trato cercano y la dedicación que nos caracteriza. ¡Descubre cómo hemos transformado sus desafíos en oportunidades!
              </p>
            </div>
          </div>
          <Swiper {...testimonialSlider} className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Usuario 1</h5>
                  <span>Directora Financiera</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>
                    Trabajar con Gekkonect ha sido una de las mejores decisiones que hemos tomado. 
                    El software que nos diseñaron para la para gestionar los procesos internos no solo ha optimizado 
                    nuestra eficiencia operativa, sino que nos ha permitido tomar decisiones más rápidas y fundamentadas 
                    gracias a su precisión. Su equipo comprendió nuestras necesidades desde el primer día, 
                    y la solución que entregaron superó nuestras expectativas. 
                    La atención personalizada y la transparencia en cada paso del desarrollo fue fundamental para el éxito 
                    del proyecto. Recomiendo Gekkonect sin dudarlo.
                  </p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + '/img/reivewer.jpg'} alt="testimonial-img" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Usuario 2</h5>
                  <span>Director Operativo</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>
                    La automatización de procesos era algo que nuestra empresa necesitaba urgentemente, y Gekkonect 
                    logró implementarla de manera impecable. Desde el análisis inicial hasta la entrega del software, 
                    su equipo fue extremadamente profesional y eficiente. Hemos reducido significativamente los tiempos 
                    de producción y, lo más importante, hemos minimizado errores humanos gracias a su solución. 
                    La capacidad de personalización y su atención a los detalles técnicos han sido clave para nuestra 
                    transformación digital. Gekkonect ha demostrado ser un socio tecnológico de confianza, 
                    comprometido con los resultados.
                  </p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + "/img/reivewer-1.jpg"} alt="testimonial-img" />
                  </div>
                </div>
              </SwiperSlide>
               <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Usuario 3</h5>
                  <span>Executive Chairman</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>
                    Desde que decidí trabajar con Gekkonect, mi experiencia ha sido excepcional. Tenía una 
                    visión clara de lo que quería para mi negocio, pero no sabía por dónde empezar. 
                    Ellos no solo me ayudaron a crear una presencia en la web que refleja la esencia de 
                    mi marca, sino que también desarrollaron un ERP a medida que ha transformado por completo 
                    la forma en que gestiono mis procesos internos.
                    La atención al detalle y el compromiso del equipo fueron evidentes desde el primer día. 
                    Cada reunión era productiva, y siempre sentí que mis ideas eran escuchadas y valoradas. Gracias a su ERP, ahora puedo optimizar mis tiempos y tener un control mucho más eficiente de mis operaciones.
                    El impacto en mi negocio ha sido notable: he podido reducir tiempos de respuesta y mejorar 
                    la satisfacción de mis clientes. No podría estar más feliz con el resultado. Recomiendo 
                    encarecidamente a Gekkonect a cualquier empresa que busque soluciones tecnológicas 
                    efectivas y un equipo que realmente se preocupa por el éxito de sus clientes.
                  </p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + '/img/reivewer.jpg'} alt="testimonial-img" />
                  </div>
                </div>
              </SwiperSlide>
              {/*<SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Jesmin korac</h5>
                  <span>Director</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>Integer purus odio, placerat nec rhoncus in, ullamcorper nec dolor. ani aptent taciti sociosqu ad litora torquent per conubia nostra, per sonic himenaeos. Praesent nec neque at dolor venenatis consectetur europ Donec lacinia placerat felis non aliquam.</p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + '/img/reivewer-1.jpg'} alt="testimonial-img" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Martha Maldonado</h5>
                  <span>Executive Chairman</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>Integer purus odio, placerat nec rhoncus in, ullamcorper nec dolor. ani aptent taciti sociosqu ad litora torquent per conubia nostra, per sonic himenaeos. Praesent nec neque at dolor venenatis consectetur europ Donec lacinia placerat felis non aliquam.</p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + '/img/reivewer.jpg'} alt="testimonial-img" />
                  </div>
                </div>
              </SwiperSlide> */}
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>  
    </>
  )
}

export default Testimonial