import React from 'react'

function HistoryArea() {
  return (
    <>
     <section className="history-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Historia</span>
              <h2>Historia de Gekkonect</h2>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={process.env.PUBLIC_URL + '/img/timeline-1.jpg'} alt="history-img" />
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2012</span>
                  <h4>Iniciamos consultorías independientes</h4>
                  <p>
                    En los inicios de Gekkonect, comenzamos brindando consultorías tecnológicas independientes, 
                    ofreciendo soluciones puntuales como software a medida, integraciones tecnológicas y optimización de 
                    sistemas para empresas emergentes. Fue aquí donde plantamos la semilla de nuestra misión: empoderar a 
                    las organizaciones a través de la tecnología innovadora. Con un enfoque personalizado en cada proyecto, 
                    nos ganamos la confianza de nuestros primeros clientes, marcando el comienzo de una larga trayectoria de 
                    éxito y satisfacción total del cliente.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2015</span>
                  <h4>Decidimos aunar esfuerzos</h4>
                  <p>
                    Tras varios años trabajando individualmente, comprendimos el poder del trabajo en equipo. 
                    En 2015, decidimos unir fuerzas, sumando talentos y conocimientos en desarrollo de software a medida, 
                    integraciones tecnológicas y soluciones en la nube para dar el siguiente gran paso. 
                    Esta decisión nos permitió ampliar nuestro alcance, asumir proyectos más desafiantes, 
                    como la optimización de sistemas y el desarrollo de aplicaciones web de alto rendimiento, 
                    cimentando las bases de lo que sería Gekkonect como empresa formal.
                  </p>
                </div>
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={process.env.PUBLIC_URL + '/img/timeline-2.jpg'} alt="history-img" />
              </div>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={process.env.PUBLIC_URL + '/img/timeline-3.jpg'} alt="history-img" />
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2018</span>
                  <h4>Nuestros primeros proyectos</h4>
                  <p>
                    Con un equipo consolidado y la visión clara, 2018 fue un año clave: los primeros grandes proyectos
                    llegaron a nuestras manos. Desde software personalizado hasta plataformas empresariales basadas en 
                    infraestructura en la nube y aplicaciones web de alto rendimiento, comenzamos a transformar ideas 
                    en soluciones tecnológicas que marcaron la diferencia para nuestros clientes, ganándonos reconocimiento 
                    en el sector por nuestra calidad, adaptabilidad y resultados excepcionales.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="500ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2021</span>
                  <h4>Establecimos el rumbo de Gekkonect</h4>
                  <p>
                    El año de la consolidación. En 2021, con una cartera creciente de clientes satisfechos,
                    nos enfocamos en establecer un rumbo claro hacia la excelencia. Definimos nuestra misión de 
                    ser una agencia comprometida con soluciones tecnológicas innovadoras, incluyendo software a medida y 
                    optimización de sistemas, manteniendo siempre el más alto estándar de calidad y dedicación. Nuestro 
                    objetivo es superar las expectativas de nuestros clientes y consolidar nuestra reputación en el sector.
                  </p>
                </div>
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="500ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={process.env.PUBLIC_URL + '/img/timeline-4.jpg'} alt="history-img" />
              </div>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={process.env.PUBLIC_URL + '/img/timeline-3.jpg'} alt="history-img" />
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2024</span>
                  <h4>Decicación completa a un sueño</h4>
                  <p>
                    2024 es el año en que Gekkonect se convierte en una realidad tangible y en plena expansión. Después de 
                    años de esfuerzo, pasión y perseverancia, dedicamos todos nuestros recursos y energía a convertirnos en 
                    líderes en el desarrollo de software a medida y en soluciones tecnológicas innovadoras. 
                    Hoy, más que nunca, estamos comprometidos a hacer realidad los sueños tecnológicos de nuestros clientes, 
                    ofreciendo aplicaciones web de alto rendimiento y optimización de sistemas que superan sus expectativas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default HistoryArea