import React from 'react'

function WhyChooseUs() {
  return (
    <>
      <section className="why-choose sec-mar wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="why-choose-left">
                <div className="choose-banner1">
                  <img src={process.env.PUBLIC_URL + "/img/why-1.jpg"} alt="why-choose images" />
                </div>
                <div className="choose-banner2">
                  <img src={process.env.PUBLIC_URL + "/img/why-2.jpg"} alt="why-choose image one" />
                  <img src={process.env.PUBLIC_URL + "/img/why-3.jpg"} alt="why-choose image two" />
                </div>
                <div className="years">
                  <h5>20+</h5>
                  <span>Años</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-right">
                <div className="sec-title layout2">
                  <span>¿Por qué escogernos?</span>
                  <h2>Tu Socio Tecnológico Estratégico</h2>
                </div>
                {/* <div className="counter-boxes">
                  <div className="count-box">
                    <span className="counter">20</span><sup>+</sup>
                    <h5>Proyectos completados</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">15</span><sup>+</sup>
                    <h5>Clientes satisfechos</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">5</span><sup>+</sup>
                    <h5>Equipos de expertos</h5>
                  </div>
                </div> */}
                <p>
                  En Gekkonect, no solo creamos software a medida, diseñamos soluciones estratégicas que potencian tu 
                  negocio. Nos destacamos por nuestra calidad, adaptabilidad y enfoque en resultados excepcionales, 
                  desde aplicaciones web de alto rendimiento hasta infraestructura en la nube y optimización de sistemas. 
                  Aseguramos que cada línea de código sea una inversión sólida en el crecimiento y la transformación 
                  digital de tu empresa.
                </p>
                <div className="buttons-group">
                  <span>Soporte 24/7</span>
                  <span>Diseño Único</span>
                  <span>Desarrollo basado en Clean Code</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default WhyChooseUs